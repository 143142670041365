<template>
  <div class="main">
    <el-row>
      <el-col :md="15" class="pr-4">
        <div>
          <h2 class="pb-2">{{ $t("TOEFLCourse.title") }}</h2>
          <div v-html="$t('TOEFLCourse.desc')"></div>
        </div>
      </el-col>
      <el-col :md="9" class="img">
        <el-image
          src="https://ivy-way.s3-ap-northeast-1.amazonaws.com/images/course/IMG_0219_meitu_1.jpg"
        />
      </el-col>
    </el-row>
    <el-divider></el-divider>

    <div class="mt-5">
      <h4>TOEFL Summer</h4>
      <el-table
        :data="toeflSchedule"
        style="width: 100%;"
        :span-method="arraySpanMethod"
        border
      >
        <el-table-column
          :label="$t('TOEFLCourse.classTitle')"
          prop="time"
          align="center"
        ></el-table-column>
        <el-table-column
          label="MON"
          prop="mon"
          align="center"
        ></el-table-column>
        <el-table-column
          label="TUE"
          prop="tue"
          align="center"
        ></el-table-column>
        <el-table-column
          label="WED"
          prop="wed"
          align="center"
        ></el-table-column>
        <el-table-column
          label="THU"
          prop="thu"
          align="center"
        ></el-table-column>
        <el-table-column
          label="FRI"
          prop="fri"
          align="center"
        ></el-table-column>
      </el-table>
    </div>

    <div class="mt-5">
      <h4>{{ $t("TOEFLCourse.tutoring") }}</h4>
      <div v-html="$t('TOEFLCourse.tutor_desc')"></div>
    </div>

    <div class="mt-5">
      <h4 class="pb-1">TOEFL Price List</h4>
      <template>
        <el-table
          :data="prices"
          style="width: 100%;"
          class="table-small"
          :stripe="true"
          height="350"
        >
          <el-table-column
            prop="num"
            label="Number of students"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="online"
            label="Online Tutoring Price"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="faceToFace"
            label="Face-to-Face Tutoring Price"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="online_8"
            label="More than 8 lessons(16hrs)- Online"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="faceToFace_8"
            label="More than 8 lessons(16hrs)- Face-to-Face"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="online_15"
            label="More than 15 lessons(30hrs)- Online"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="faceToFace_15"
            label="More than 15 lessons(30hrs)- Face-to-Face"
            align="center"
          ></el-table-column>
        </el-table>
      </template>
    </div>
    <div class="mt-4">
      <h4>{{ $t("TOEFLCourse.tutoring") }}</h4>
      <div v-html="$t('TOEFLCourse.tutor_desc')"></div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    toeflSchedule() {
      return [
        {
          time: "9:00-12:00",
          mon: "Speaking",
          tue: "Writing",
          wed: "Listening",
          thu: "Mock Test",
          fri: "Reading"
        },
        {
          time: "13:00-14:00",
          mon: "Vocabulary",
          tue: "Mock Test Review"
        },
        {
          time: "14:00-17:00",
          mon: "Study Time"
        }
      ];
    },
    prices() {
      return [
        {
          num: "1 on 1",
          online: "1600 NTD/hr",
          faceToFace: "2000 NTD/hr",
          online_8: "1500 NTD/hr",
          faceToFace_8: "1800 NTD/hr",
          online_15: "1200 NTD/hr",
          faceToFace_15: "1500 NTD/hr"
        },
        {
          num: "1 on 2",
          online: "1400 NTD/hr",
          faceToFace: "1750 NTD/hr",
          online_8: "1200 NTD/hr",
          faceToFace_8: "1500 NTD/hr",
          online_15: "1000 NTD/hr",
          faceToFace_15: "1300 NTD/hr"
        },
        {
          num: "1 on 3",
          online: "1200 NTD/hr",
          faceToFace: "1500 NTD/hr",
          online_8: "1000 NTD/hr",
          faceToFace_8: "1200 NTD/hr",
          online_15: "900 NTD/hr",
          faceToFace_15: "1200 NTD/hr"
        },
        {
          num: "1 on 4",
          online: "1000 NTD/hr",
          faceToFace: "1200 NTD/hr",
          online_8: "900 NTD/hr",
          faceToFace_8: "1000 NTD/hr",
          online_15: "800 NTD/hr",
          faceToFace_15: "1100 NTD/hr"
        },
        {
          num: "1 on 5",
          online: "900 NTD/hr",
          faceToFace: "1000 NTD/hr",
          online_8: "800 NTD/hr",
          faceToFace_8: "1100 NTD/hr",
          online_15: "700 NTD/hr",
          faceToFace_15: "1000 NTD/hr"
        }
      ];
    }
  },
  methods: {
    toRoute(name) {
      this.$router.push({
        name: name
      });
    },
    arraySpanMethod({ rowIndex, columnIndex }) {
      if (rowIndex === 1 || rowIndex === 2) {
        if (columnIndex === 1) return [1, 4];
      }
      if (columnIndex === 2) {
        if (rowIndex === 1) return [2, 1];
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.main {
  margin: 60px;
}
.img {
  padding-left: 20px;
  padding-top: 50px;
}

@media (max-width: 992px) {
  .img {
    padding-top: 20px;
    padding-left: 20px;
  }
}
@media (max-width: 992px) {
  .table-small {
    font-size: 12px;
  }
  .main {
    margin: 40px;
  }
}

@media (max-width: 732px) {
  .table-small {
    font-size: 10px;
  }
  .main {
    margin: 30px;
  }
}

@media (max-width: 500px) {
  .table-small {
    font-size: 10px;
  }
  .main {
    margin: 20px;
  }
}
</style>
